import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.50.1_react-d_7883ef1638e983dc61bb1608794f4df2/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.50.1_react-d_7883ef1638e983dc61bb1608794f4df2/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.50.1_react-d_7883ef1638e983dc61bb1608794f4df2/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.50.1_react-d_7883ef1638e983dc61bb1608794f4df2/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.50.1_react-d_7883ef1638e983dc61bb1608794f4df2/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.50.1_react-d_7883ef1638e983dc61bb1608794f4df2/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.23.2_@opentelemetry+api@1.7.0_@playwright+test@1.50.1_react-d_7883ef1638e983dc61bb1608794f4df2/node_modules/next/dist/lib/metadata/metadata-boundary.js");
